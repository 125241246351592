import '@/styles/main.scss';
import $ from 'jquery';

document.documentElement.classList.remove('no-js');
window.$ = $;

async function main() {
  const _LazySizesBgSet = await import('lazysizes/plugins/bgset/ls.bgset');
  const { init: initLazySizes } = await import('lazysizes');
  const { default: initLegacyJs } = await import('@/js/legacy');

  // init lazySizes
  initLazySizes();

  // init legacy js
  initLegacyJs();
}

main()
  .then(() => {})
  .catch((err) => {
    console.error(err);
  });

if (import.meta.hot) {
  import.meta.hot.accept(() => console.log('HMR'));
}
